<template>
    <activix-banner
        color="orange-400"
        :dismissable="false"
        v-if="transferRequested"
    >
        <span class="font-semibold truncate mr-1">
            {{ $t(
                'banner.nylasTransfer.title',
            ) }}
        </span>
        <a
            target="_blank"
            :href="loginPath"
        >
            {{ $t('banner.nylasTransfer.clickHere') }}
        </a>
        <activix-tooltip :content="$t('banner.nylasTransfer.tooltip')">
            <icon class="text-sm ml-1 shrink-0" name="regular/information-circle" />
        </activix-tooltip>
    </activix-banner>
</template>

<script>
    import { mapState } from 'pinia';
    import qs from 'qs';
    import { useGlobalStore } from '@/store/store.js';

    export default {
        data() {
            return {
                emailConfig: {},
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            loginPath() {
                const payload = {
                    client_id: process.env.VUE_APP_NYLAS_CLIENT_ID,
                    redirect_uri: `${process.env.VUE_APP_API_URL}/mail-config/v3/result`,
                    response_type: 'code',
                    access_type: 'offline',
                    state: this.authUser.access_token,
                };

                const params = qs.stringify(payload, { encode: false });

                return `${process.env.VUE_APP_NYLAS_URL_V3}/v3/connect/auth?${params}`;
            },

            transferRequested() {
                if (this.authUser.hasChildren || !this.emailConfig) {
                    return false;
                }

                return (
                    this.emailConfig.active &&
                    !this.emailConfig.transferred &&
                    !this.emailConfig.is_nylas_grant_filled
                );
            },
        },

        methods: {
            async getEmailConfig() {
                this.emailConfig = await this.authUser.email_config;
            },
        },

        created() {
            this.getEmailConfig();
        },
    };
</script>
