/* eslint-disable camelcase */
import { findContextAccount } from '@/pinia/storeHelper.js';
import i18n from '@/plugins/vue-i18n.js';
import ActivixDate from '@/value-objects/ActivixDate.js';

import Account from '@/entities/Account.js';
import CaslConsentState from '@/entities/CaslConsentState.js';
import CaslConsentStatus from '@/entities/CaslConsentStatus.js';
import CaslConsentType from '@/entities/CaslConsentType.js';
import ContactType from '@/entities/ContactType.js';
import CustomerEmail from '@/entities/CustomerEmail.js';
import CustomerPhone from '@/entities/CustomerPhone.js';
import CustomerVehicle from '@/entities/CustomerVehicle.js';
import LeadEmailType from '@/entities/LeadEmailType.js';
import LeadPhoneType from '@/entities/LeadPhoneType.js';
import Model from '@/entities/Model.js';
import User from '@/entities/User.js';

export default class Customer extends Model {
    static get defaults() {
        return {
            account: {},
            casl_consent: {
                confirmation: {},
            },
            emails: [],
            latest_user: {},
            latest_bdc_user: {},
            latest_commercial: {},
            leads: [],
            phones: [],
            vehicles: [],
        };
    }

    constructor(initialValues = {}) {
        super(initialValues);
    }

    set account(account) {
        this._account = account;
    }

    get account() {
        if (!this.relationLoaded('account') && this.account_id) {
            this._account = findContextAccount(this.account_id) || new Account(this._account);
        }

        return this._account;
    }

    set latest_user(latestUser) {
        this._latest_user = new User(latestUser);
    }

    get latest_user() {
        return this._latest_user;
    }

    set latest_bdc_user(latestBdcUser) {
        this._latest_bdc_user = new User(latestBdcUser);
    }

    get latest_bdc_user() {
        return this._latest_bdc_user;
    }

    set latest_commercial(latestCommercial) {
        this._latest_commercial = new User(latestCommercial);
    }

    get latest_commercial() {
        return this._latest_commercial;
    }

    set vehicles(vehicles) {
        this._vehicles = vehicles.filter(v => !CustomerVehicle.isConsideredEmpty(v));// .map(v => new CustomerVehicle(v));
    }

    get vehicles() {
        return this._vehicles;
    }

    get fullName() {
        return [this.first_name, this.last_name].join(' ').trim();
    }

    get displayName() {
        if (this.isBusinessContact && this.business_name) {
            return this.business_name.trim();
        }

        if (this.isSecondContact && this.second_contact) {
            return this.second_contact.trim();
        }

        return this.fullName;
    }

    get isBusinessContact() {
        return this.primary_contact_type === ContactType.BUSINESS;
    }

    get isSecondContact() {
        return this.primary_contact_type === ContactType.SECOND;
    }

    get isManualConsent() {
        return this.isConfirmedConsent && this.casl_consent.confirmed_type === CaslConsentType.MANUAL;
    }

    get isRevokedConsent() {
        return this.casl_consent.revoked;
    }

    get isDoubleOptInConsent() {
        return this.isConfirmedConsent && this.casl_consent.confirmed_type === CaslConsentType.DOUBLE_OPT_IN;
    }

    get consentState() {
        if (this.isRevokedConsent) {
            return CaslConsentState.EXPIRED;
        }

        return this.casl_consent.confirmed_at ? CaslConsentState.CONFIRMED : CaslConsentState.TEMPORARY;
    }

    get consentStateString() {
        return CaslConsentState.getTranslation(this.consentState);
    }

    get isConfirmedConsent() {
        return this.consentState === CaslConsentState.CONFIRMED;
    }

    get isTemporaryConsent() {
        return this.consentState === CaslConsentState.TEMPORARY;
    }

    get consentStatus() {
        const nowDate = new ActivixDate('now');
        const warningDate = nowDate.addDays(14);
        const limitDate = new ActivixDate(this.casl_consent.limit_date);

        if (this.isRevokedConsent) {
            return CaslConsentStatus.EXPIRED;
        }

        if (this.isConfirmedConsent) {
            return CaslConsentStatus.ACTIVE;
        }

        if (limitDate.isSameOrAfter(warningDate)) {
            return CaslConsentStatus.ACTIVE;
        }

        if (limitDate.isBetween(nowDate, warningDate)) {
            return CaslConsentStatus.ABOUT_TO_EXPIRE;
        }

        return CaslConsentStatus.EXPIRED;
    }

    get consentStatusString() {
        return CaslConsentStatus.getTranslation(this.consentStatus);
    }

    get isActiveConsent() {
        return this.consentStatus === CaslConsentStatus.ACTIVE;
    }

    get isAboutToExpireConsent() {
        return this.consentStatus === CaslConsentStatus.ABOUT_TO_EXPIRE;
    }

    get isExpiredConsent() {
        return this.consentStatus === CaslConsentStatus.EXPIRED || this.isRevokedConsent;
    }

    get temporaryConsentDaysLeft() {
        let daysLeft = 0;
        const limitDate = new ActivixDate(this.casl_consent.limit_date);

        if (!this.casl_consent.confirmed_at && limitDate.isFuture()) {
            daysLeft = limitDate.diffInDays(new ActivixDate('now')) || 1;
        }

        return daysLeft;
    }

    get temporaryConsentDaysLeftString() {
        let daysLeft = this.temporaryConsentDaysLeft || i18n.t('general.none');

        if (this.isConfirmedConsent) {
            daysLeft = '∞';
        }

        return `${daysLeft} ${i18n.tc('consent.daysLeft', this.temporaryConsentDaysLeft)}`;
    }

    get consentAllowsContact() {
        return [CaslConsentStatus.ACTIVE, CaslConsentStatus.ABOUT_TO_EXPIRE].includes(this.consentStatus);
    }

    get orderedPhones() {
        return [...this.phones]
            .sort((a, b) => b.created_at.localeCompare(a.created_at))
            .map(phone => new CustomerPhone(phone));
    }

    get cellPhone() {
        const cellPhone = this.orderedPhones.find(item => item.type === LeadPhoneType.CELL);

        return cellPhone || new CustomerPhone({ type: LeadPhoneType.CELL });
    }

    get homePhone() {
        const homePhone = this.orderedPhones.find(item => item.type === LeadPhoneType.HOME);

        return homePhone || new CustomerPhone({ type: LeadPhoneType.HOME });
    }

    get workPhone() {
        const workPhone = this.orderedPhones.find(item => item.type === LeadPhoneType.WORK);

        return workPhone || new CustomerPhone({ type: LeadPhoneType.WORK });
    }

    get orderedEmails() {
        return [...this.emails]
            .sort((a, b) => b.created_at.localeCompare(a.created_at))
            .map(email => new CustomerEmail(email));
    }

    get homeEmail() {
        const homeEmail = this.orderedEmails.find(item => item.type === LeadEmailType.HOME);

        return homeEmail || new CustomerEmail({ type: LeadEmailType.HOME });
    }

    get workEmail() {
        const workEmail = this.orderedEmails.find(item => item.type === LeadEmailType.WORK);

        return workEmail || new CustomerEmail({ type: LeadEmailType.WORK });
    }
}
