export default {
    alerts: {
        index: {
            error: 'Failure during the search',
        },
    },
    lead: 'Lead',
    note: 'Notes',
    recent: 'Recents',
    results: 'Results',
    search: 'Search...',
    user: 'Users',
    vehicle: 'Vehicles',
};
