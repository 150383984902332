/* eslint-disable camelcase */
import uEmojiParser from 'universal-emoji-parser';
import i18n from '@/plugins/vue-i18n.js';
import { formatPhone } from '@/utils/index.js';
import { timeHms } from '@/utils/time.js';
import { maskEmail, maskPhone } from '@/utils/mask.js';

import ActivixDate from '@/value-objects/ActivixDate.js';
import CommunicationKind from '@/entities/CommunicationKind.js';
import CommunicationMethod from '@/entities/CommunicationMethod.js';
import CommunicationStatus from '@/entities/CommunicationStatus.js';
import CommunicationType from '@/entities/CommunicationType.js';
import Lead from '@/entities/Lead.js';
import Model from '@/entities/Model.js';
import Service from '@/entities/Service.js';
import User from '@/entities/User.js';
import UserRepository from '@/graphql/repositories/UserRepository.js';
import VideoConferenceStatus from '@/entities/VideoConferenceStatus.js';
import VideoRecordingStatus from '@/entities/VideoRecordingStatus.js';

export default class Communication extends Model {
    static get defaults() {
        return {
            lead_id: null,
            user_id: null,
        };
    }

    constructor(initialValues = {}) {
        super(initialValues);
    }

    set attachments(attachments) {
        this._attachments = attachments || [];
    }

    set guests(guests) {
        this._guests = guests || [];
    }

    set user(user) {
        this._user = user;
    }

    set lead(lead) {
        this._lead = new Lead(lead);
    }

    get attachments() {
        return this._attachments || [];
    }

    get guests() {
        return this._guests || [];
    }

    get user() {
        return this.getUser();
    }

    get lead() {
        return this._lead || new Lead();
    }

    get isEmail() {
        return this.communication_method_id === CommunicationMethod.EMAIL;
    }

    get isMessenger() {
        return this.communication_method_id === CommunicationMethod.MESSENGER;
    }

    get isPhone() {
        return this.communication_method_id === CommunicationMethod.PHONE;
    }

    get isSms() {
        return this.communication_method_id === CommunicationMethod.SMS;
    }

    get isVideo() {
        return this.communication_method_id === CommunicationMethod.VIDEO;
    }

    get isIncoming() {
        return this.communication_type_id === CommunicationType.INCOMING;
    }

    get isOutgoing() {
        return this.communication_type_id === CommunicationType.OUTGOING;
    }

    get isPlanned() {
        return this.kind === CommunicationKind.PLANNED || !!this.automation;
    }

    get isConnected() {
        return this.connected &&
            ![CommunicationStatus.INTERRUPTED, CommunicationStatus.ERROR, CommunicationStatus.INVALID, CommunicationStatus.PENDING].includes(this.status);
    }

    get isVideoConferenceOver() {
        return ![CommunicationStatus.PENDING, CommunicationStatus.ATTEMPTED, CommunicationStatus.CALLING].includes(this.status);
    }

    get formattedCreatedAt() {
        if (this.original_created_at) {
            return new ActivixDate(this.original_created_at).toHumanShort();
        }

        return new ActivixDate(this.created_at).toHumanShort();
    }

    get formattedDuration() {
        if (!this.duration_call) {
            return '';
        }

        return timeHms(this.duration_call);
    }

    get formattedDurationReached() {
        if (!this.duration_reached || this.duration_reached === 'N/A') {
            return '';
        }

        return timeHms(this.duration_reached);
    }

    get formattedEmailReadOn() {
        if (!this.isEmail || !this.isOutgoing || !this.email_read_at) {
            return '';
        }

        const readOn = new ActivixDate(this.email_read_at);

        return readOn.formatDistanceToNow();
    }

    get formattedExcerpt() {
        if (this.isEmail && this.email_body) {
            const parser = new DOMParser().parseFromString(this.email_body, 'text/html');
            const content = parser.body.textContent || '';
            return content.replace(/\u00a0/g, ' ');
        }

        if (this.isSms && this.excerpt) {
            return uEmojiParser.parseToUnicode(this.excerpt);
        }

        return this.excerpt;
    }

    get formattedPhone() {
        return formatPhone(this.phone, true);
    }

    get formattedUrl() {
        if (!this.url) {
            return '';
        }

        if (!this.url.endsWith('.wav') && !this.url.endsWith('.mp3')) {
            return `${this.url}.wav`;
        }

        return this.url;
    }

    get maskedClientEmail() {
        if (!this.client_email) {
            return '';
        }

        return maskEmail(this.client_email);
    }

    get maskedPhone() {
        if (!this.phone) {
            return '';
        }

        return maskPhone(this.formattedPhone);
    }

    get videoConferenceStatus() {
        if (!this.isVideo) {
            return null;
        }

        if (this.isVideoConferenceOver) {
            return VideoConferenceStatus.OVER;
        }

        if (this.status === CommunicationStatus.CALLING) {
            return VideoConferenceStatus.LIVE;
        }

        return VideoConferenceStatus.PLANNED;
    }

    get videoRecordingStatus() {
        if (!this.isVideo) {
            return null;
        }

        if (!this.video_room.allow_recording) {
            return VideoRecordingStatus.NOT_RECORDED;
        }

        if (!this.isVideoConferenceOver) {
            return VideoRecordingStatus.PENDING;
        }

        if ([CommunicationStatus.INTERRUPTED, CommunicationStatus.UNANSWERED].includes(this.status)) {
            return VideoRecordingStatus.NOT_RECORDED;
        }

        if (this.url) {
            return VideoRecordingStatus.RECORDING_AVAILABLE;
        }

        return VideoRecordingStatus.PROCESSING;
    }

    get titlePrefix() {
        if (this.isPlanned) {
            return i18n.t('clientCard.boxes.communications.planned');
        }

        return '';
    }

    get directionText() {
        return this.isOutgoing ? i18n.t('general.outgoing') : i18n.t('general.incoming');
    }

    get manualText() {
        return this.created_by_user ? i18n.t('clientCard.boxes.communications.manual') : '';
    }

    get typeText() {
        if (this.isEmail) {
            return this.media?.ziggeo_id
                ? i18n.t('users.edit.emailVideo')
                : i18n.t('users.edit.email');
        }

        if (this.isSms) {
            return i18n.t('users.edit.sms');
        }

        if (this.isMessenger) {
            return i18n.t('users.edit.messenger');
        }

        if (this.isVideo) {
            return i18n.t('clientCard.videoconference');
        }

        if (this.isPhone) {
            return i18n.tc('general.call', 1);
        }

        return '';
    }

    getTitle(lead) {
        const title = i18n.locale === 'fr'
            ? [this.typeText, this.directionText, this.manualText]
            : [this.manualText, this.directionText, this.typeText];

        return [
            this.titlePrefix,
            title.filter(part => part).join(' '),
            this.getSubTypeText(lead),
        ].filter(part => part).join(' - ');
    }

    getSubTypeText(lead) {
        if (this.created_by_user) {
            return '';
        }

        if (this.kind === CommunicationKind.MASS_MAILING) {
            return i18n.t('options.massMailing');
        }

        if (this.service_id === Service.WEBBOOST) {
            return i18n.t('general.webBoost');
        }

        if (this.call_log) {
            return i18n.t('clientCard.callLogCommunication');
        }

        if (this.service_id === Service.TELBOOST && lead.ivr) {
            return i18n.t('phoneProvider.ivr');
        }

        return '';
    }

    get userId() {
        return this.user_id || this._user?.id;
    }

    set userId(userId) {
        this.user_id = userId;
    }

    async getUser(fields = []) {
        if (!this.userId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.userId, fields));
    }
}
