<template>
    <div class="flex">
        <div class="flex items-center">
            <activix-select
                :multiple="true"
                value-key="id"
                label-key="text"
                :filterable="true"
                :same-width="false"
                :options="contactableUsers"
                ref="alertUsers"
                @input="updateAlertUsers"
                v-model="mentions"
            >
                <template #trigger="{ selected }">
                    <div class="flex items-center relative">
                        <div class="flex items-center pointer-events-none h-4 min-w-4 p-1 shadow justify-center absolute bg-blue-500 text-sm text-white rounded-full mb-5 ml-4 font-normal" v-text="selected.length" v-if="selected.length" />
                        <button
                            class="flex items-center justify-center w-6 h-6 | transition duration-200"
                            :class="selected.length ? 'text-blue-500' : 'text-gray-600 | hover:text-gray-800'"
                            v-tooltip="$t('clientCard.addCommentMentions')"
                        >
                            <icon name="regular/single-neutral" class="text-lg" />
                        </button>
                    </div>
                </template>
            </activix-select>
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */
    import { mapState } from 'pinia';
    import { ActivixSelect } from '@autosync/atx-activix-ui';
    import { useContextStore } from '@/store/modules/context/store.js';

    import { cloneDeep, uniqBy, differenceBy } from 'lodash-es';

    import Comment from '@/entities/Comment.ts';

    export default {
        name: 'CommentNotification',

        components: {
            ActivixSelect,
        },

        props: {
            comment: {
                type: Comment,
                required: true,
            },
            privateNote: {
                type: Boolean,
                required: true,
            },
            users: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                mentions: [],
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            activeUsers() {
                return this.users
                    .filter(user => user.isSelectable || this.comment.alert_users.some(guest => guest.id == user.id))
                    .map(user => ({
                        id: user.id,
                        role_id: user.role_id,
                        text: user.fullName,
                        post_id: user.post_id,
                    }));
            },

            contactableUsers() {
                const guestGroups = this.contextAccount.guest_groups.map(group => {
                    return {
                        id: `G${group.id}`,
                        isGroup: true,
                        text: `[${group.name}]`,
                        posts: group.posts,
                        roles: group.roles,
                        users: group.users,
                    };
                });

                const activeUsers = this.activeUsers.map(user => ({
                    id: user.id,
                    text: user.text,
                }));

                return guestGroups.concat(activeUsers);
            },
        },

        watch: {
            'comment.parent_user': {
                immediate: true,
                handler(newValue) {
                    if (!newValue || this.comment.alert_users.find(user => user.id == newValue.id)) {
                        return;
                    }

                    const comment = cloneDeep(this.comment);
                    comment.alert_users.push(newValue);

                    this.$emit('update:comment', comment);
                },
            },

            'comment.alert_users': {
                immediate: true,
                handler(newValue) {
                    this.mentions = newValue.map(user => user.id);
                },
            },

            mentions() {
                this.$refs.alertUsers.search = '';
                this.$refs.alertUsers.$refs.search.focus();
            },
        },

        methods: {
            updateAlertUsers(users) {
                const results = this.contactableUsers.filter((user) => {
                    return users.find(id => id === user.id);
                });

                const comment = cloneDeep(this.comment);
                comment.alert_users = this.updateGroups(results);

                this.$emit('update:comment', comment);
            },

            updateGroups(users) {
                const guestRemoved = differenceBy(this.comment.alert_users, users, 'id');
                const guestAdded = differenceBy(users, this.comment.alert_users, 'id');
                const groupRemoved = guestRemoved.find(guest => guest.isGroup);
                const groupAdded = guestAdded.find(guest => guest.isGroup);

                if (groupAdded) {
                    const usersToCheckByRoles = this.getActiveUsersBySelectedRoles(groupAdded.roles);
                    const usersToCheckByPosts = this.getActiveUsersBySelectedPosts(groupAdded.posts);
                    const usersToCheckByUsers = this.getActiveUsersFromGuestGroups(groupAdded.users);

                    const combinedUsersToChecked = uniqBy(usersToCheckByRoles.concat(usersToCheckByPosts).concat(usersToCheckByUsers), 'id');

                    return uniqBy(users.concat(combinedUsersToChecked), 'id');
                }

                if (groupRemoved) {
                    const usersToUncheckByRoles = this.getActiveUsersBySelectedRoles(groupRemoved.roles);
                    const usersToUncheckByPosts = this.getActiveUsersBySelectedPosts(groupRemoved.posts);
                    const usersToUncheckByUsers = this.getActiveUsersFromGuestGroups(groupRemoved.users);

                    const combinedUsersToUnchecked = uniqBy(usersToUncheckByRoles.concat(usersToUncheckByPosts).concat(usersToUncheckByUsers), 'id');

                    return users.filter(guest => !combinedUsersToUnchecked.some(user => user.id == guest.id));
                }

                if (guestAdded.length > 0) {
                    const groups = this.getGuestGroup(users).map(group => {
                        return {
                            id: `G${group.id}`,
                            isGroup: true,
                            text: `[${group.name}]`,
                            roles: group.roles,
                            users: group.users,
                            posts: group.posts,
                        };
                    });

                    return uniqBy(users.concat(groups), 'id');
                }

                if (guestRemoved.length > 0) {
                    const guestGroupsToRemove = this.getGuestGroup(users, true);

                    if (guestGroupsToRemove.length > 0) {
                        return users.filter(guest => {
                            return !guestGroupsToRemove.some(group => group.key == guest.id);
                        });
                    }
                }

                return users;
            },

            getGuestGroup(users, remove = false) {
                const guestGroups = this.contextAccount.guest_groups;

                let removedGuestGroups = [];
                guestGroups.forEach(group => {
                    const usersId = group.users;

                    const selectedUsersPerGuestGroup = users.filter(user => usersId.includes(user.id));

                    if (remove && usersId.length != selectedUsersPerGuestGroup.length) {
                        removedGuestGroups = removedGuestGroups.concat(group);
                    } else if (usersId.length == selectedUsersPerGuestGroup.length) {
                        removedGuestGroups = removedGuestGroups.concat(group);
                    }
                });

                return removedGuestGroups;
            },

            getActiveUsersBySelectedRoles(roles) {
                return this.activeUsers.filter(user => roles.includes(user.role_id));
            },

            getActiveUsersFromGuestGroups(users) {
                return this.activeUsers.filter(user => users.includes(user.id));
            },

            getActiveUsersBySelectedPosts(posts) {
                return this.activeUsers.filter(user => posts.includes(user.post_id));
            },
        },
    };
</script>
