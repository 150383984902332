import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(payload = {}) {
        if (!payload.accountId) {
            throw new InvalidPayload('Account ID not provided.');
        }

        const response = await axios.get('v2/leads', { params: payload });

        return response.data.data;
    },

    async store(payload = {}) {
        const response = await axios.post('v1/leads', payload);

        return response.data.data;
    },

    async show(id, payload = {}) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.get(`v1/leads/${id}`, { params: payload });

        return response.data.data;
    },

    async checkCommunicating(id) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.get(`v1/leads/${id}/check-communicating`);

        return response.data.data;
    },

    async delete(query = {}, payload = {}) {
        if (!query.id && !query.ids && !query.search) {
            throw new InvalidPayload('Query not provided.');
        }

        if (query.id) {
            return axios.delete(`v1/leads/delete/${query.id}`);
        }

        if (query.ids) {
            return axios.delete('v1/leads/delete/bulk', {
                data: {
                    ...payload,
                    leadIds: query.ids,
                },
            });
        }

        return axios.delete('v1/leads/delete/bulk', {
            data: {
                ...payload,
                ...query.search,
            },
        });
    },

    async update(query = {}, payload = {}) {
        if (!query.id && !query.ids && !query.search) {
            throw new InvalidPayload('Query not provided.');
        }

        if (query.id) {
            return axios.put(`v1/leads/${query.id}`, payload);
        }

        if (query.ids) {
            return axios.put('v1/leads/bulk', {
                ...payload,
                leadIds: query.ids,
            });
        }

        return axios.put('v1/leads/bulk', {
            ...payload,
            ...query.search,
        });
    },

    async email(query = {}, payload = {}) {
        if (!query.id && !query.ids && !query.search) {
            throw new InvalidPayload('Query not provided.');
        }

        if (!(payload instanceof FormData)) {
            throw new InvalidPayload('Payload must be an instance of "FormData".');
        }

        if (query.id) {
            return axios.post(`v1/leads/email/${query.id}`, payload);
        }

        if (query.ids) {
            query.ids.forEach(id => {
                payload.append('leadIds[]', id);
            });

            return axios.post('v1/leads/email/bulk', payload);
        }

        payload.append('search', JSON.stringify(query.search));

        return axios.post('v1/leads/email/bulk', payload);
    },

    async event(query = {}, payload = {}) {
        if (!query.ids && !query.search) {
            throw new InvalidPayload('Query not provided.');
        }

        if (query.ids) {
            return axios.post('v1/leads/event/bulk', {
                ...payload,
                leadIds: query.ids,
            });
        }

        return axios.post('v1/leads/event/bulk', {
            ...payload,
            ...query.search,
        });
    },

    async associate(query = {}, payload = {}) {
        if (!query.id && !query.search) {
            throw new InvalidPayload('Query not provided.');
        }

        if (query.id) {
            return axios.post(`v1/leads/associate/${query.id}`, payload);
        }

        return axios.post('v1/leads/associate/bulk', {
            ...payload,
            ...query.search,
        });
    },

    async dissociate(id) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        return axios.post(`v1/leads/dissociate/${id}`);
    },

    async merge(query = {}, payload = {}) {
        if (!query.id) {
            throw new InvalidPayload('Query not provided.');
        }

        return axios.post(`v1/leads/merge/${query.id}`, payload);
    },

    async addEventToSalesJourney(primaryLeadId, payload = {}) {
        if (!primaryLeadId) {
            throw new InvalidPayload('ID not provided.');
        }

        return axios.post(`v1/leads/${primaryLeadId}/add-event-to-sales-journey`, payload);
    },

    async addLeadToSalesJourney(primaryLeadId, leadId) {
        if (!primaryLeadId || !leadId) {
            throw new InvalidPayload('ID not provided.');
        }

        return axios.post(`v1/leads/${primaryLeadId}/add-lead-to-sales-journey/${leadId}`);
    },

    async setCommunicating(query = {}, payload = {}) {
        if (!query.id && !query.ids && !query.search) {
            throw new InvalidPayload('Query not provided.');
        }

        if (query.id) {
            return axios.post(`v1/leads/set-communicating/${query.id}`, payload);
        }

        if (query.ids) {
            return axios.post('v1/leads/set-communicating/bulk', {
                ...payload,
                leadIds: query.ids,
            });
        }

        return axios.post('v1/leads/set-communicating/bulk', {
            ...payload,
            ...query.search,
        });
    },

    async removeCommunicating(query = {}, payload = {}) {
        if (!query.id && !query.ids && !query.search) {
            throw new InvalidPayload('Query not provided.');
        }

        if (query.id) {
            return axios.post(`v1/leads/remove-communicating/${query.id}`, payload);
        }

        if (query.ids) {
            return axios.post('v1/leads/remove-communicating/bulk', {
                ...payload,
                leadIds: query.ids,
            });
        }

        return axios.post('v1/leads/remove-communicating/bulk', {
            ...payload,
            ...query.search,
        });
    },

    async createPrebooking(payload = {}) {
        if (!payload.lead_id) {
            throw new InvalidPayload('Lead id not provided.');
        }

        if (!payload.start_at) {
            throw new InvalidPayload('Start date not provided.');
        }

        return axios.post('v1/leads/service-prebooking', payload);
    },
};
