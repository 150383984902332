import { cloneDeep, isArray, isEmpty } from 'lodash-es';

export type BaseModelType = Record<string, any>;
export type BaseModelWithId = BaseModelType & { id: any };

export default abstract class BaseModel {
    protected init(initialValues: Record<string, any>) {
        for (const [field, value] of Object.entries(initialValues || {})) {
            (this as BaseModelType)[field] = value;
        }
    }

    clone(): this {
        return cloneDeep(this);
    }

    /**
     * Caching and object pooling
     */

    relationLoaded(relation: string): boolean {
        const relationKey = `_${relation}`;
        return (this as BaseModelType)[relationKey] instanceof BaseModel && (this as BaseModelType)[relationKey].id;
    }

    loaded(attribute: string, property: string | null = null): boolean {
        const value = (this as BaseModelType)[`_${attribute}_cache`];

        if (isEmpty(value)) {
            return false;
        }

        const valueModel = isArray(value) ? value[0] : value;

        if (!(valueModel instanceof BaseModel)) {
            return false;
        }

        if (!property) {
            return true;
        }

        if (!valueModel.hasOwnProperty('id')) {
            return false;
        }

        return (valueModel as unknown as BaseModelWithId).id === (this as Record<string, any>)[property];
    }

    load(attribute: string, property: string | null = null): any | null {
        return this.loaded(attribute, property) ? (this as BaseModelType)[`_${attribute}_cache`] : null;
    }

    store(attribute: string, data: any): any {
        (this as BaseModelType)[`_${attribute}_cache`] = data;
        return data;
    }

    free(attribute: string): void {
        (this as BaseModelType)[`_${attribute}_cache`] = null;
        delete (this as BaseModelType)[`_${attribute}_cache`];
    }
}
