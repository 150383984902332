<template>
    <activix-modal :name="name" @closed="onClosed" @before-open="onBeforeOpen">
        <template slot="header">
            <h4 class="modal-title">
                {{ $tc('general.note', 2) }}
            </h4>
        </template>
        <template slot="body">
            <comment :lead-id="leadId" :users="users" v-if="leadId" />
        </template>
    </activix-modal>
</template>

<script>
    import Comment from '../comment/Comment.vue';

    export default {
        name: 'CommentModal',

        components: {
            Comment,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
            users: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                leadId: null,
            };
        },

        methods: {
            onClosed() {
                this.leadId = null;
            },

            async onBeforeOpen(leadId) {
                this.leadId = leadId;
            },
        },
    };
</script>
