import Model from './Model.js';

export default class Group extends Model {
    static get defaults() {
        return {};
    }

    constructor(initialValues = {}) {
        super(initialValues);
    }
}
