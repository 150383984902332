import Vue from 'vue';
import UserRepository from '@/graphql/repositories/UserRepository.js';
import BaseModel, { BaseModelType } from '@/entities/BaseModel.ts';
import { isEqual } from 'lodash-es';
import CommentType from './CommentType.js';
import User from './User.js';

export default class Comment extends BaseModel {
    id: number | null = null;

    alert: boolean = false;

    alert_email: boolean = false;

    alert_sms: boolean = false;

    alert_user_id: number | null = null;

    auto_delete: boolean = false;

    comment_type_id: number = CommentType.STRING;

    content: string = '';

    created_at: string = '';

    created_by: number | null = null;

    deleted_at: string | null = null;

    deleted_by: number | null = null;

    file_url: string | null = null;

    fullname: string = '';

    lead_event_id: number | null = null;

    media?: any = null;

    media_id: number | null = null;

    parent_id: number | null = null;

    private: boolean = false;

    request_user_id: number | null = null;

    updated_at: string = '';

    updated_by: number | null = null;

    lead_id: string | null = null;

    user_id: string | null = null;

    file?: File | null = null;

    file_mime_type?: string | null = null;

    private _user?: User;

    private _children?: Comment[];

    private _parent?: Comment;

    private _alert_users?: (string | number | User)[];

    constructor(initialValues: any = {}) {
        super();
        this.init(initialValues);
    }

    set user(user: User) {
        this._user = user;
    }

    get user(): Promise<User> {
        return this.getUser();
    }

    get children(): Comment[] {
        return this._children || [];
    }

    set children(children: Comment[]) {
        this._children = (children || []).map(comment => new Comment(comment));
    }

    get parent(): Comment {
        return this._parent || new Comment();
    }

    set parent(parent: Comment) {
        this._parent = new Comment(parent);
    }

    get alert_users(): (string | number | User)[] {
        return this._alert_users || [];
    }

    set alert_users(alertUsers: (string | number | User)[]) {
        this._alert_users = (alertUsers || []).map(user => {
            if (typeof user === 'string' || Number.isInteger(user)) {
                return user;
            }

            if (Vue.router.currentRoute.name === 'leads.update' && Vue.feature.isEnabled('new-lead-page') && typeof user === 'object' && 'id' in user) {
                return (user as User).id;
            }

            return new User(user);
        });
    }

    get userId(): string | null {
        return this.user_id || this._user?.id;
    }

    async getUser(fields: string[] = []): Promise<User> {
        if (!this.userId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.userId, fields));
    }

    isNotEmpty() {
        const defaultComment = new Comment();
        for (const [field, value] of Object.entries(defaultComment)) {
            if (!isEqual((this as BaseModelType)[field], value)) {
                return false;
            }
        }

        return true;
    }
}
